import { Col, Row } from 'antd';

export default function Projects() {
  return (
    <Row style={{ padding: '0 20px' }} gutter={[40, 0]} align="middle" justify="space-between">
      <Col xs={24}>
        Coming soon...
      </Col>
    </Row>
  );
}