import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

type LoaderProps = {
  height?: number
}

export const Loader = ({ height = 50 }: LoaderProps) => {
  return (
    <div>
      <div style={{ minHeight: `${height}px`, marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: height }} spin />} />
      </div>
    </div>
  )
};
