import { Col, Layout, Menu, Row } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { atom, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import logo from '../assets/logo1.png';
import About from './About';
import Contact from './Contact';
import PortfolioWrapper from './PortfolioWrapper';
import Projects from './Projects';
import { portfoliosState } from './state/image.state';

export const layoutRefState = atom({
  key: 'layoutRefState',
  default: null
});

export default function Landing() {
  const setLayoutRef = useSetRecoilState(layoutRefState);
  const ref = useRef();
  const portfolioLoadable = useRecoilValueLoadable(portfoliosState);
  const portfolios = portfolioLoadable.state === 'hasValue' ? portfolioLoadable.contents : [];

  useEffect(() => {
    setLayoutRef(ref);
  }, [ref]);

  const renderMenu = () => {
    return (
      <Menu
        theme="light"
        mode="horizontal"
        className="nav-menu"
        overflowedIndicator={<MenuOutlined />}
        style={{ justifyContent: 'center', borderBottom: 'none' }}
      >
        <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
        <Menu.SubMenu key="portfolio" title="Portfolio">
          {portfolios?.filter((p) => !p.isDefault).map((portfolio: any) => (
            <Menu.Item key={portfolio.path}>
              <Link to={`/portfolio/${portfolio.path}`}>{portfolio.name}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.Item key="projects"><Link to="/projects">Projects</Link></Menu.Item>
        {/*  <Menu.Item key="about"><Link to="/about">About</Link></Menu.Item>*/}
        <Menu.Item key="contact"><Link to="/contact">Contact</Link></Menu.Item>
      </Menu>
    );
  };

  return (
    <Layout className="layout" style={{ minHeight: '100%', overflowX: 'scroll', backgroundColor: 'white' }}>
      <Layout.Header style={{ backgroundColor: 'white' }}>
        <Row justify="center">
          <Col xs={24}>
            <img className="logo" src={logo} style={{ paddingTop: '10px' }} />
          </Col>
          <Col xs={24}>
            {renderMenu()}
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ height: '100%' }}>
        <Routes>
          <Route path="portfolio/:portfolioPath" element={<PortfolioWrapper />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="projects" element={<Projects />} />
          <Route path="/" element={<PortfolioWrapper />} />
        </Routes>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        Kristel Puusta Photograhy ©2022
      </Layout.Footer>
    </Layout>
  );
}