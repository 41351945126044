import { MeResponse } from '../admin/model/MeResponse';
import KPPApi from "./KPPApi";

export default class AuthApi {
  public static login = (username: string, password: string): Promise<MeResponse> => {
    return KPPApi.post(`/public/auth/login`, { username: username, password: password }, { withCredentials: true });
  }

  public static me = (): Promise<MeResponse> => {
    return KPPApi.get(`/public/auth/me`);
  }

  public static logout = (): Promise<string> => {
    return KPPApi.post(`/public/auth/logout`, { withCredentials: true });
  }
}
