import { MeResponse } from '../model/MeResponse';

const USER_KEY = 'user';

class AuthStorageService {

  static storeUser(authUser: MeResponse): void {
    localStorage.setItem(USER_KEY, JSON.stringify(authUser));
  }

  static getUserFromStorage(): MeResponse {
    const str = localStorage.getItem(USER_KEY);
    return str && JSON.parse(str);
  }

  static clearUserStorage(): void {
    localStorage.removeItem(USER_KEY);
  }

  static clearAll(): void {
    this.clearUserStorage();
    // TODO: clear recoil states
  }
}

export default AuthStorageService;
