import { Loadable, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Image, Portfolio } from '../model/Portfolio';
import { targetWidth, numberOfColumns, portfoliosImagesState, selectedPortfolioPathState, portfoliosState } from './image.state';

export type ImageActions = {
  images: Loadable<Image[]>;
  portfolios: Loadable<Portfolio[]>;
  columnsCount: number;
  currentPortfolioPath: string;
  setCurrentPortfolioPath: (path: string) => void;
  targetWidth: number;
}

export function useImageActions(): ImageActions {
  const [currentPortfolioPath, setCurrentPortfolioPath] = useRecoilState(selectedPortfolioPathState);
  const portfolioImages = useRecoilValueLoadable(portfoliosImagesState(currentPortfolioPath));
  const columnsCount = useRecoilValue(numberOfColumns);
  const tw = useRecoilValue(targetWidth);
  const portfolios = useRecoilValueLoadable(portfoliosState);

  return {
    images: portfolioImages,
    columnsCount,
    currentPortfolioPath,
    setCurrentPortfolioPath,
    portfolios,
    targetWidth: tw
  };
}
