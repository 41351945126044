import AuthApi from '../../api/AuthApi';
import { MeResponse } from '../model/MeResponse';
import AuthStorageService from './auth.storage.service';

export const JWT_STORAGE_KEY = "authenticationJwt";

class AuthService {

  static storeJwt(jwt: string): void {
    localStorage.setItem(JWT_STORAGE_KEY, jwt);
  }

  static async login(email: string, password: string): Promise<MeResponse> {
    const user = await AuthApi.login(email, password);
    AuthStorageService.storeUser(user);
    return user;
  }

  static async whoAmI(force?: boolean): Promise<MeResponse> {
    const user = force || AuthStorageService.getUserFromStorage() != null ? await AuthApi.me() : null;
    this.updateUserInStorage(user);
    return user;
  }

  static async logout(): Promise<void> {
    await AuthApi.logout();
    this.updateUserInStorage(null);
  }

  private static updateUserInStorage(user: MeResponse | null): void {
    if (!!user) {
      AuthStorageService.storeUser(user);
    } else {
      AuthStorageService.clearUserStorage();
    }
  }
}

export default AuthService;
