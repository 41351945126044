import { useState } from 'react';
import { Image as Img } from './model/Portfolio';
import { Image } from 'antd';

interface ImageWrapperProp {
  image: Img;
}

export default function ImageWrapper({ image }: ImageWrapperProp) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div key={`img-${image.id}`} className="image-wrapper" style={{ width: image.previewImage.width, height: image.previewImage.height }}>
      <Image
        className={`fade-in ${loaded ? 'loaded' : ''}`}
        preview={{ src: image.path }}
        width={image.previewImage.width}
        height={image.previewImage.height}
        src={image.previewImage.path}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </div>
  );
}
