import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthActions from '../state/auth.actions';

export default function AuthPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { authenticate, logOut } = useAuthActions();

  const onFinish = ({ username, password }: { username: string, password: string }) => {
    setLoading(true);
    authenticate(username, password)
      .then(() => navigate('/admin/portfolios'))
      .finally(() => setLoading(false));
  };

  const modalContent = () => {
    return (
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="username">
          <Input />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password />
        </Form.Item>
      </Form>
    );
  };

  const renderButtons = () => {
    return [
      <Button key="button-1" type="primary" loading={loading} onClick={form.submit}>Log in</Button>,
      <Button
        key="button-2"
        onClick={() => {
          logOut();
          navigate('/');
        }}
      >
        Cancel
      </Button>
    ];
  };

  return (
    <Modal visible={true} closable={false} footer={renderButtons()}>
      {modalContent()}
    </Modal>
  )
}