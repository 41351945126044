import { atom } from 'recoil';
import { MeResponse } from '../model/MeResponse';

const getUserFromStorage = (): MeResponse => {
  return JSON.parse(localStorage.getItem('user') as string) as MeResponse;
}

export const initializedState = atom<boolean>({
  key: 'initialized',
  default: false
});

export const authState = atom<MeResponse | null>({
  key: 'auth',
  default: getUserFromStorage()
});
