import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import GalleryApi from '../api/GalleryApi';
import { Loader } from '../common/helpers/Loader';
import Portfolio from './Portfolio';
import { portfoliosState } from './state/image.state';

export default function PortfolioWrapper() {
  const { portfolioPath } = useParams();
  const portfolios = useRecoilValue(portfoliosState);
  const [loading, setLoading] = useState(false);
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    setLoading(true);
    setImgs([]);

    GalleryApi.getPortfolioImages(portfolios.find((p) => portfolioPath ? p.path === portfolioPath : p.isDefault)?.id).then((result) => {
      setImgs(result);
      setLoading(false);
    });
  }, [portfolioPath, portfolios]);

  return (
    <>
      {loading ? <Loader /> : <Portfolio images={imgs} />}
    </>
  );
}
