import { Button, Form, FormInstance, Input, InputNumber, Upload } from 'antd';
import { useEffect } from 'react';
import { AdminImage } from '../../public/model/Portfolio';

type ImageEditFormProps = {
  portfolioId: number;
  image: AdminImage;
  form: FormInstance;
  onSave: (formData: FormData) => Promise<any>;
  onCancel: () => void;
}

export default function ImageEditForm({ form, image, onSave }: ImageEditFormProps) {
  useEffect(() => {
    form.setFieldsValue({ id: image.id });
    form.setFieldsValue({ alt: image.alt });
    form.setFieldsValue({ orderNum: image.orderNum });
    form.setFieldsValue({ file: undefined });
  }, [form, image]);

  function saveImage(image: AdminImage): Promise<any> {
    const formData = new FormData();
    if (image.id) {
      formData.append("imageId", image.id?.toString());
    }
    if (image.alt) {
      formData.append("alt", image.alt);
    }
    if (image.orderNum) {
      formData.append("orderNum", image.orderNum?.toString());
    }
    if ((image as any).fileList) {
      formData.append("file", (image as any).fileList[0].originFileObj);
    }

    return onSave(formData);
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form form={form} onFinish={saveImage} labelCol={{ xs: 6 }} wrapperCol={{ xs: 16 }}>
      <Form.Item name="id" label="Id">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="alt" label="Alt">
        <Input />
      </Form.Item>
      <Form.Item name="orderNum" label="Order">
        <InputNumber />
      </Form.Item>
      <Form.Item name="fileList" label="Image" valuePropName="fileList" getValueFromEvent={normFile}>
        <Upload beforeUpload={() => false} listType="picture" multiple={false}>
          <Button>Select image</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
}
