import { Button, Form, Modal, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminApi from '../../api/AdminApi';
import { AdminImage } from '../../public/model/Portfolio';
import ImageEditForm from './ImageEditForm';
import { formatISO9075 } from 'date-fns';

export default function AdminPortfolio() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [images, setImages] = useState<AdminImage[]>();
  const [loadingList, setLoadingList] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [editImage, setEditImage] = useState<AdminImage>();

  useEffect(() => {
    loadImages(Number(id));
  }, [id]);

  function loadImages(portfolioId: number) {
    setLoadingList(true);
    AdminApi.getPortfolioImages(Number(portfolioId))
      .then((result) => {
        setImages(result);
      })
      .finally(() => {
        setLoadingList(false);
      });
  }

  function deleteImage(imageId: number): Promise<any> {
    const portfolioId = Number(id);
    return AdminApi.deleteImage(portfolioId, imageId).then(() => {
      loadImages(portfolioId);
    })
  }

  function renderActions(img: AdminImage) {
    return (
      <Space className="float-right">
        <Button onClick={() => setEditImage(img)}>Edit</Button>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={async () => {
            await deleteImage(img.id);
            loadImages(Number(id));
          }}>
          <Button danger={true}>Delete</Button>
        </Popconfirm>
      </Space>
    )
  }

  const renderPreview = (image: AdminImage) => {
    return <img alt="" src={image.previewImage.path} style={{ width: '100px', height: 'auto' }} />;
  };

  return (
    <div>
      <Space>
        <Button type="primary" onClick={() => setEditImage({} as AdminImage)}>Add image</Button>
        <Button onClick={() => loadImages(Number(id))}>Reload</Button>
      </Space>
      <br />
      <br />
      <Modal
        width={600}
        onCancel={() => setEditImage(null)}
        visible={!!editImage}
        footer={[
          <Button key="save-image" loading={formLoading} onClick={() => form.submit()}>Save</Button>,
          <Button key="close-modal" onClick={() => setEditImage(null)}>Close</Button>
        ]}
      >
        <ImageEditForm
          portfolioId={Number(id)}
          image={editImage}
          form={form}
          onSave={(formData) => {
            setFormLoading(true);
            const imageId = Number(formData.get('imageId'));
            let req;
            if (!!imageId) {
              req = AdminApi.updateImage(Number(id), imageId, formData);
            } else {
              req = AdminApi.createImage(Number(id), formData);
            }
            return req
              .then(() => {
                form.resetFields();
                setEditImage(null);
                loadImages(Number(id));
              })
              .finally(() => {
                setFormLoading(false);
              });
          }}
          onCancel={() => setEditImage(null)}
        />
      </Modal>
      <Table
        dataSource={images}
        pagination={false}
        loading={loadingList}
        rowKey="id"
        columns={[
          { title: 'Id', dataIndex: 'id', width: '100px' },
          { title: 'Alt', dataIndex: 'alt' },
          { title: 'Width', dataIndex: 'width', width: '100px' },
          { title: 'Height', dataIndex: 'height', width: '100px' },
          { title: 'Order', dataIndex: 'orderNum', width: '100px' },
          { title: 'CreatedAt', width: '200px', key: 'createdAt', render: (row) => <span>{formatISO9075(new Date(row.createdAt))}</span> },
          { title: 'ModifiedAt', width: '200px', key: 'modifiedAt', render: (row) => <span>{formatISO9075(new Date(row.modifiedAt))}</span> },
          { title: 'Preview', width: '150px', render: renderPreview },
          { title: 'Actions', width: '200px', key: 'actions', render: renderActions },
        ]}
      />
    </div>
  );
}
