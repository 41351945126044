import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ImageWrapper from './ImageWrapper';
import debounce from 'lodash.debounce';
import { useImageActions } from './state/image.action';
import { Image, Image as Img } from './model/Portfolio';
import { MasonryGrid } from '@egjs/react-grid';

export type PortfolioProps = {
  images: Image[]
};

export default function Portfolio({ images }: PortfolioProps) {
  const { targetWidth, columnsCount } = useImageActions();
  const [imagesCount, setImagesCount] = useState(12);
  const loadMoreRef = React.createRef<HTMLDivElement>();
  const debouncedLoadMore = debounce(loadMore, 200);

  useEffect(() => {
    setImagesCount(12);
  }, [images]);

  useEffect(() => {
    function isElementVisible(element: HTMLDivElement) {
      const rect = element.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        Math.round(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
        Math.round(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function handleScroll() {
      if (!loadMoreRef.current) {
        return;
      }

      const isVisible = isElementVisible(loadMoreRef.current);
      if (isVisible) {
        debouncedLoadMore();
      }
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreRef]);

  async function loadMore(): Promise<void> {
    const newCount = imagesCount + 3;
    setImagesCount(Math.min(newCount, images.length));
  }

  const getCurrentImages = () => {
    return images
      .slice(0, imagesCount)
      .map((image: Img) => {
        const ratio = image.previewImage.height / image.previewImage.width;

        return {
          ...image,
          previewImage: {
            ...image.previewImage,
            width: targetWidth,
            height: targetWidth * ratio
          }
        }
      });
  }

  const renderGrid = () => {
    return (
      <MasonryGrid
        className="container"
        gap={5}
        defaultDirection={"end"}
        align={"center"}
        autosize={true}
        column={columnsCount}
        renderOnPropertyChange={true}
        useTransform={true}
        useFit={true}
      >
        {getCurrentImages().map((image: Img) => (
          <React.Fragment key={image.id}>
            <ImageWrapper image={image} />
          </React.Fragment>
        ))}
      </MasonryGrid>
    );
  };

  return (
    <Row>
      <Col xs={24}>
        {renderGrid()}
      </Col>
      <Col xs={24}>
        {<div ref={loadMoreRef} />}
      </Col>
    </Row>
  );
}
