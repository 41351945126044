import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import profile from '../assets/profile.jpeg';

const links = [
  { label: 'Facebook', url: 'https://www.facebook.com/kristelpuustaphotography', icon: <FacebookOutlined /> },
  { label: 'Instagram', url: 'https://www.instagram.com/kristelpuustaphoto/', icon: <InstagramOutlined /> }
];

export default function Contact() {
  const [form] = Form.useForm();

  const submit = (props: any) => {
    window.location.href = `mailto:kristelpuustaphotograpy@gmail.com?subject=${props.name}&body=${props.comment}`;
  };

  const renderSocialMediaLinks = () => {
    return (
      <div className="social-media-links">
        <Space>
          {links.map((link) => <a target="_blank" rel="noreferrer" href={link.url}>{link.icon}</a>)}
        </Space>
      </div>
    );
  };

  return (
    <>
      <Row gutter={[40, 0]} align="middle" justify="space-around" style={{ padding: '0 20px' }}>
        <Col xs={24} sm={8}>
          <img
            alt=""
            src={profile}
            style={{ width: '100%', maxWidth: '400px', padding: '15px', borderRadius: '50vh', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
        </Col>
        <Col xs={24} sm={16}>
          <div style={{ lineHeight: 2, fontSize: '16px' }}>
            Kristel started with photography at the early age of 13-14, when she first picked up his fathers old 1MP camera to discover photography world.
            She started with self-portraiture and conceptual photography.
            She bought herself a camera in 2007, Canon 400D.
            In 2011 she got her first full-frame camera Canon EOS 5D mark II and after that she photographs people.
            She loves to shoot outdoors, combining nature, people and natural light.
            Nowadays she works mainly with Canon R5.
          </div>
        </Col>
      </Row>
      <Row gutter={[40, 0]} style={{ padding: '0 20px' }}>
        <Col xs={24} sm={{ span: 8 }}>
          {renderSocialMediaLinks()}
        </Col>
        <Col xs={{ span: 24, offset: 0}} sm={{ span: 15, offset: 8 }}>
          <div>
            <Form form={form} layout="vertical" onFinish={submit} wrapperCol={{ xs: 24, sm: 24 }}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
              <Form.Item name="comment" label="Message" rules={[{ required: true }]}>
                <Input.TextArea placeholder="Message" rows={4} maxLength={3000} showCount={true} />
              </Form.Item>
              <Button type="primary" htmlType="submit">Send</Button>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}