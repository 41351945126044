import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { atom, useSetRecoilState } from 'recoil';
import AdminLanding from './admin/AdminLanding';
import AuthPage from './admin/auth/AuthPage';
import useAuthActions from './admin/state/auth.actions';
import { Loader } from './common/helpers/Loader';
import Landing from './public/Landing';

export const appViewportState = atom({
  key: 'appViewport',
  default: {
    width: window.innerWidth,
    height: window.innerHeight
  }
});

function App() {
  const setAppViewPort = useSetRecoilState(appViewportState);

  useEffect(() => {
    function handleResize() {
      setAppViewPort({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="admin/login" element={<AuthPage />} />
            <Route path="admin/*" element={<AdminLanding />} />
            <Route path="/*" element={<Landing />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
