import { Col, Layout, Menu, Row } from 'antd';
import React, { useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Loader } from '../common/helpers/Loader';
import AdminPortfolio from './portfolios/AdminPortfolio';
import AdminPortfolios from './portfolios/AdminPortfolios';
import useAuthActions from './state/auth.actions';

export default function AdminLanding() {
  const navigate = useNavigate();
  const { initialized, isAuthenticated, logOut, initialize } = useAuthActions();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (initialized && !isAuthenticated) {
      navigate('/admin/login');
    }
  }, [isAuthenticated, initialized]);

  const renderMenu = () => (
    <Menu theme="light" mode="horizontal" style={{ justifyContent: 'flex-end', borderBottom: 'none' }} defaultSelectedKeys={['gallery']}>
      <Menu.Item key="portfolios"><Link to="/admin/portfolios">Portfolios</Link></Menu.Item>
      <Menu.Item key="projects"><Link to="/admin/projects">Projects</Link></Menu.Item>
      <Menu.Item key="customer-galleries"><Link to="/admin/customer-galleries">Customer Galleries</Link></Menu.Item>
      <Menu.SubMenu key="settings" title="Settings">
        <Menu.Item
          key="log-out"
          onClick={() => {
            logOut();
            navigate('/admin/login');
          }}
        >
          Log Out
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <Layout className="layout" style={{ minHeight: '100%', overflowX: 'scroll', backgroundColor: 'white' }}>
      <Layout.Header style={{ backgroundColor: 'white' }}>
        <Row>
          <Col xs={12}>
            <img src={logo} style={{ width: '250px', paddingTop: '20px' }} />
          </Col>
          <Col xs={12}>
            {initialized && isAuthenticated && renderMenu()}
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ height: '100%', margin: '24px 50px 0', overflow: 'initial' }}>
        {initialized && isAuthenticated ? (
          <Routes>
            <Route path="portfolios/*" element={<AdminPortfolios />} />
            <Route path="portfolios/:id" element={<AdminPortfolio />} />
          </Routes>
        ) : (
          <Loader />
        )}
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        Kristel Puusta Photograhy ©2022
      </Layout.Footer>
    </Layout>
  );
}
