import { useRecoilState } from 'recoil';
import AuthService from './auth.service';
import { authState, initializedState } from './auth.state';

export type AuthContext = {
  initialized: boolean;
  initialize: () => void;
  isAuthenticated: boolean;
  authenticate: (username: string, password: string) => Promise<any>;
  logOut: () => void;
}

export default function useAuthActions(): AuthContext {
  const [auth, setAuth] = useRecoilState(authState);
  const [initialized, setInitialized] = useRecoilState(initializedState);

  function initialize(): void {
    whoAmI(true);
  }

  async function whoAmI(force?: boolean): Promise<void> {
    await AuthService.whoAmI().then((user) => {
      setAuth(user);
    }).finally(() => {
      setInitialized(true);
    });
  }

  async function authenticate(username: string, password: string) {
    const user = await AuthService.login(username, password);
    setAuth(user);
  }

  async function logOut() {
    await AuthService.logout();
    setAuth(null);
  }

  return {
    initialized,
    initialize,
    isAuthenticated: !!auth,
    authenticate,
    logOut
  };
}
