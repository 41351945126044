import { atom, selector, selectorFamily } from 'recoil';
import GalleryApi from '../../api/GalleryApi';
import { appViewportState } from '../../App';
import { Image, Portfolio } from '../model/Portfolio';

export const selectedPortfolioPathState = atom<string>({
  key: 'selectedPortfolioPathState',
  default: ''
});

export const portfoliosState = selector<Portfolio[]>({
  key: 'portfoliosState',
  get: () => {
    return GalleryApi.getPortfoliosList();
  }
});

export const portfoliosImagesState = selectorFamily<Image[], string>({
  key: 'portfoliosImagesState',
  get: (portfolioPath: string) => ({ get }) => {
    const portfolios = get(portfoliosState);
    const id = portfolios.find((p: any) => p.path === portfolioPath)?.id;
    if (id) {
      return GalleryApi.getPortfolioImages(id);
    } else {
      return [];
    }
  }
});

export const numberOfColumns = selector<number>({
  key: 'numberOfColumns',
  get: ({ get }) => {
    const { width } = get(appViewportState);
    if (width < 650 ) {
      return 1;
    } else if (width < 1100) {
      return 2;
    } else if (width < 1650) {
      return 3;
    } else {
      return 4;
    }
  }
})

export const targetWidth = selector<number>({
  key: 'columnRatio',
  get: ({ get }) => {
    const { width } = get(appViewportState);
    if (width < 600) {
      return 300;
    } else if (width < 900) {
      return 325;
    } else if (width < 1200) {
      return 350;
    } else if (width < 1450) {
      return 375;
    } else {
      return 400;
    }
  }
});
