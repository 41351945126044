import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const API_CONF: AxiosRequestConfig = {
  // baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:8000/kris-api',
  baseURL: '/kris-api',
  timeout: 50000,
  withCredentials: true,
};

class KPPApi {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create(API_CONF);
  }

  public async get<R>(url: string): Promise<R> {
    const apiCallPromise = this.api.get(url);
    return this.handleAsyncError(apiCallPromise);
  }

  public async post<REQ, RES>(url: string, data?: REQ, config?: AxiosRequestConfig): Promise<RES> {
    const apiCallPromise = this.api.post<REQ, AxiosResponse<RES>>(url, data, config);
    return this.handleAsyncError(apiCallPromise);
  }

  public async put<REQ, RES>(url: string, data?: REQ, config?: AxiosRequestConfig): Promise<RES> {
    const apiCallPromise = this.api.put<REQ, AxiosResponse<RES>>(url, data, config);
    return this.handleAsyncError(apiCallPromise);
  }

  public async delete<REQ, RES>(url: string): Promise<RES> {
    const apiCallPromise = this.api.delete<REQ, AxiosResponse<RES>>(url);
    return this.handleAsyncError(apiCallPromise);
  }

  private async handleAsyncError<RES>(apiCallPromise: Promise<AxiosResponse<RES>>) {
    try {
      const result = await apiCallPromise;
      const data: any = result.data;
      if (data?.error) {
        this.handleApplicationLogicError(data);
      }
      return data;
    } catch (e) {
      throw this.handleError(e);
    }
  }

  private handleApplicationLogicError(data: any): void {
    const error = new Error() as AxiosError;
    error.code = data.error;
    throw error;
  }

  private handleError<RES>(e: any) {
    if (!e.isAxiosError) {
      console.error(e);
    } else {
      this.handleAxiosError(e as AxiosError<RES>);
    }
    return e;
  }

  private handleAxiosError<RES>(e: AxiosError<RES>) {
    const status = e.response?.status;
    if (!status || status === 500) {
      console.error(e);
    } else if ((status === 401 || status === 403) && window.location.pathname !== '/admin/login') {
      window.location.assign('/admin/login');
    }
  }
}

export default new KPPApi();
