import { AdminImage, Portfolio } from '../public/model/Portfolio';
import KPPApi from "./KPPApi";

export default class AdminApi {
  public static createPortfolio = (portfolio: Portfolio): Promise<Portfolio> => {
    return KPPApi.post(`/api/portfolio`, portfolio);
  }

  public static updatePortfolio = (portfolioId: number, portfolio: Portfolio): Promise<Portfolio> => {
    return KPPApi.put(`/api/portfolio/${portfolioId}`, portfolio);
  }

  public static setPortfolioAsDefault = (portfolioId: number): Promise<Portfolio> => {
    return KPPApi.put(`/api/portfolio/${portfolioId}/set-default`);
  }

  public static deletePortfolio = (portfolioId: number): Promise<Portfolio> => {
    return KPPApi.delete(`/api/portfolio/${portfolioId}`);
  }

  public static getPortfolioImages = (portfolioId: number): Promise<AdminImage[]> => {
    return KPPApi.get(`/api/portfolio/${portfolioId}/images`);
  }

  public static createImage = (portfolioId: number, formData: FormData): Promise<AdminImage> => {
    return KPPApi.post(`/api/portfolio/${portfolioId}/image`, formData, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public static updateImage = (portfolioId: number, imageId: number, formData: FormData): Promise<AdminImage> => {
    return KPPApi.put(`/api/portfolio/${portfolioId}/image/${imageId}`, formData, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public static deleteImage = (portfolioId: number, imageId: number): Promise<void> => {
    return KPPApi.delete(`/api/portfolio/${portfolioId}/image/${imageId}`);
  }
}
