import { Col, Row } from 'antd';
import profile from '../assets/profile.jpeg';

export default function About() {
  return (
    <Row style={{ padding: '0 20px' }} gutter={[40, 0]} align="middle" justify="space-between">
      <Col xs={24} sm={12}>
        <div style={{ lineHeight: 2, fontSize: '16px', padding: '50px' }}>
          Kristel started with photography at the early age of  13-14, when she first picked up his fathers old 1mpx camera to discover photography world. She started with self-portraiture and conceptual photography.  She bought herself a camera 2007 , canon 400d. 2011 she got her first full-frame camera Canon eos 5d mark ii and after that she photographs people. She loves to shoot outdoors, combining  nature, people and natural light.
        </div>
      </Col>
      <Col xs={24} sm={12}>
        <img
          alt=""
          src={profile}
          style={{ width: '100%', maxWidth: '600px', padding: '15px', borderRadius: '50vh', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
      </Col>
    </Row>
  );
}