import { Image, Portfolio } from '../public/model/Portfolio';
import KPPApi from "./KPPApi";

export default class GalleryApi {
  public static getPortfoliosList = (): Promise<Portfolio[]> => {
    return KPPApi.get(`/public/portfolios`);
  }

  public static getPortfolioImages = (portfolioId: number): Promise<Image[]> => {
    return KPPApi.get(`/public/portfolio/${portfolioId}/images`);
  }
}
