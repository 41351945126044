import { Button, Form, Modal, Popconfirm, Space, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AdminApi from '../../api/AdminApi';
import GalleryApi from '../../api/GalleryApi';
import { Portfolio } from '../../public/model/Portfolio';
import PortfolioEditForm from './PortfolioEditForm';

export default function AdminPortfolios() {
  const [form] = Form.useForm();
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [loadingList, setLoadingList] = useState(false);
  const [editPortfolio, setEditPortfolio] = useState<Portfolio>();

  useEffect(() => {
    loadList();
  }, []);

  async function loadList() {
    setLoadingList(true);
    GalleryApi.getPortfoliosList()
      .then((response) => {
        setPortfolios(response);
      }).finally(() => {
      setLoadingList(false);
    })
  }

  const renderActions = (portfolio: Portfolio) => {
    return (
      <Space className="float-right">
        <Link to={`/admin/portfolios/${portfolio.id}`}><Button>Images</Button></Link>
        <Button onClick={() => setEditPortfolio(portfolio)}>Edit</Button>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          disabled={portfolio.isDefault}
          onConfirm={async () => {
            await AdminApi.setPortfolioAsDefault(portfolio.id);
            loadList();
          }}>
          <Button disabled={portfolio.isDefault}>Set default</Button>
        </Popconfirm>
        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => onDelete(portfolio.id)}>
          <Button danger={true}>Delete</Button>
        </Popconfirm>
      </Space>
    )
  };

  const onSave = (portfolio: Portfolio) => {
    if (!!portfolio.id) {
      return AdminApi.updatePortfolio(portfolio.id, { ...editPortfolio, ...portfolio })
        .then(() => {
          onClose();
          loadList();
        });
    } else {
      return AdminApi.createPortfolio({ ...editPortfolio, ...portfolio })
        .then(() => {
          onClose();
          loadList();
        });
    }
  };

  const onClose = () => {
    setEditPortfolio(null);
  };

  const addNew = () => {
    setEditPortfolio({} as Portfolio);
  };

  const onDelete = (id: number) => {
    return AdminApi.deletePortfolio(id);
  }

  const modalButtons = () => {
    return [
      <Button onClick={() => form.submit()}>Save</Button>,
      <Button onClick={() => onClose()}>Close</Button>
    ];
  }

  const renderModal = () => {
    return (
      <Modal
        visible={!!editPortfolio}
        title={editPortfolio?.name}
        footer={modalButtons()}
        width={800}
        onCancel={() => onClose()}
      >
        <PortfolioEditForm portfolio={editPortfolio} form={form} onSave={onSave} onCancel={onClose} />
      </Modal>
    );
  };

  return (
    <div>
      <Typography.Title level={4}>Portfolios</Typography.Title>
      <br />
      <Button onClick={() => addNew()}>Add new</Button>
      <Button onClick={() => loadList()}>Reload</Button>
      <br />
      <br />
      {renderModal()}
      <Table
        columns={[
          { title: 'Id', dataIndex: 'id', key: 'id' },
          { title: 'Name', dataIndex: 'name', key: 'name' },
          { title: 'Path', dataIndex: 'path', key: 'path' },
          { title: 'Order', dataIndex: 'orderNum', key: 'orderNum' },
          { title: 'CreatedAt', dataIndex: 'createdAt', key: 'createdAt' },
          { title: 'ModifiedAt', dataIndex: 'modifiedAt', key: 'modifiedAt' },
          { title: 'Default', dataIndex: 'isDefault', key: 'isDefault', render: (value) => <span>{value ? 'true' : 'false'}</span> },
          { title: 'Actions', width: '250px', key: 'actions', render: renderActions },
        ]}
        dataSource={loadingList ? [] : portfolios}
        loading={loadingList}
      />
    </div>
  );
}
