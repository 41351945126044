import { Form, FormInstance, Input, InputNumber } from 'antd';
import { useEffect } from 'react';
import { Portfolio } from '../../public/model/Portfolio';

type PortfolioEditFormProps = {
  portfolio: Portfolio;
  form: FormInstance;
  onSave: (portfolio: Portfolio) => Promise<any>;
  onCancel: () => void;
}
export default function PortfolioEditForm({ form, portfolio, onSave }: PortfolioEditFormProps) {

  useEffect(() => {
    form.setFieldsValue({ id: portfolio.id });
    form.setFieldsValue({ name: portfolio.name });
    form.setFieldsValue({ path: portfolio.path });
    form.setFieldsValue({ orderNum: portfolio.orderNum });
    form.setFieldsValue({ enabled: true });
  }, [form, portfolio]);

  return (
    <Form form={form} onFinish={onSave} labelCol={{ xs: 6 }} wrapperCol={{ xs: 16 }}>
      <Form.Item name="id" label="Id">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="path" label="Path">
        <Input />
      </Form.Item>
      <Form.Item name="orderNum" label="Order">
        <InputNumber />
      </Form.Item>
    </Form>
  );
}
